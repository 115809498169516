<template>
  <div
    :class="{ 'alert-dismissible': dismissible }"
    class="alert alert-danger fade show"
    ref="el"
    role="alert"
  >
    <div>{{ message }}</div>
    <button
      v-if="dismissible"
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
    ></button>
  </div>
</template>

<script>
import { Alert } from 'bootstrap';

export default {
  name: 'BootstrapAlert',
  props: {
    message: {
      type: String,
      default: '',
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      alert: null,
    };
  },
  mounted() {
    const { el } = this.$refs;

    this.alert = new Alert(el);

    ['close', 'closed'].forEach((e) => {
      el.addEventListener(`${e}.bs.alert`, () => {
        this.$emit(e);
      });
    });
  },
};
</script>
